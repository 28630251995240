<template>
  <div id="register" class="text-center">
    <form @submit.prevent="register">
      <h1 class="mt-3">Create Account</h1>
      <div role="alert" v-if="registrationErrors">
        {{ registrationErrorMsg }}
      </div>

      <b-col class="mb-1">
        <label for="username">Username</label>
        <input type="text" id="username" class="custom-input" v-model="user.username" required autofocus />
      </b-col>

      <b-col class="mb-1">
        <label for="password">Password</label>
        <input type="password" id="password" class="custom-input" v-model="user.password" required />
      </b-col>

      <b-col class="mb-4">
        <label for="confirmPassword">Confirm Password</label>
        <input type="password" id="confirmPassword" class="custom-input" v-model="user.confirmPassword" required />
      </b-col>

      <b-col class="mb-3">
        <button type="submit">Create Account</button>
      </b-col>
      
      <b-col>
        <p>
          <router-link :to="{ name: 'login' }">Already have an account? Log in.</router-link>
        </p>
      </b-col>
    </form>
  </div>
</template>

<script>
import authService from '../services/AuthService';

export default {
  name: 'register',
  data() {
    return {
      user: {
        username: '',
        password: '',
        confirmPassword: '',
        role: 'user',
      },
      registrationErrors: false,
      registrationErrorMsg: 'There were problems registering this user.',
    };
  },
  methods: {
    register() {
      if (this.user.password != this.user.confirmPassword) {
        this.registrationErrors = true;
        this.registrationErrorMsg = 'Password & Confirm Password do not match.';
      } else {
        authService
          .register(this.user)
          .then((response) => {
            if (response.status == 201) {
              this.$router.push({
                path: '/login',
                query: { registration: 'success' },
              });
            }
          })
          .catch((error) => {
            const response = error.response;
            this.registrationErrors = true;
            if (response.status === 400) {
              this.registrationErrorMsg = 'Bad Request: Validation Errors';
            }
          });
      }
    },
    clearErrors() {
      this.registrationErrors = false;
      this.registrationErrorMsg = 'There were problems registering this user.';
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  #register {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
